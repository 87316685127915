import React from "react";
import Posters from "../components/x-mas-2024-poster";
import './events.css'
const Events = () =>{
    return(
        < div className="eventspages" style={{justifyContent: 'center', display: "flex", flexDirection: "column",alignItems: 'center'}}>
            <Posters
                imagepath='images/posters/2024/April-edition.png'
                headertext="BIZARRE BAZAAR EASTER FESTIVAL 2025. "
                venue=" KSTVET,  Off Avenue Rd, Gigiri." 
                date=" Sat 5th and Sun 6th April."  
                vendors="April vendor application currently closed." 
                button="/eventpage"
            />
            <Posters
                imagepath='/images/posters/2024/christmas-2024.webp'
                headertext="BIZARRE BAZAAR CHRISTMAS FESTIVAL 2024."
                venue=" KSTVET, 0ff Avenue Rd, Gigiri."
                date=" FRI 13TH, SAT 14TH, SUN 15TH, DEC 2024."
                vendors=""
                vendorslink=""
                button='/xmas-2024'
            />
            <Posters
                imagepath='/images/posters/2024/july-2024.webp'
                headertext="BIZARRE BAZAAR JULY FESTIVAL 2024.  "
                venue=" KSTVET, Off Avenue Rd, Gigiri."
                date=" SAT 27TH, SUN 28TH, JULY 2024."
                button="/events"
                vendors="July 2024 festival vendors. "
                vendorslink="/vendors"
            />
           
            
        </div>
    );
};
export default Events   