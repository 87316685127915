import React from "react";
import './about.css'

const About = () =>{
    return(
        <div className="outer-about">
            <div className="About">
                <div className="about-image"/>
                <div className="About-write-up">
                    <h2>Introduction</h2>
                    <p> We specialize in designing, planning, and managing unique, immersive events that captivate and engage. Our focus goes beyond simply organizing events—to curating unforgettable experiences that seamlessly blend art, entertainment, culture, sports, and education, all in harmony with the theme or vision.
                        With nearly 20 years of expertise, Biz Baz Events is your go-to partner for turning any event—whether a corporate gathering or an intimate celebration—into a one-of-a-kind experience. We bring creativity, precision, and passion to every project.
                    </p>
                </div>
            </div>
            <div className="About">
                <div className="About-write-up">
                    <h2>Why us ?</h2>
                    <ul>
                        <li>Proven Experience: Two decades of delivering standout events across various industries.</li>
                        <li>Tailored Approach: Each event is unique, and our custom approach ensures your vision comes to life.</li>
                        <li>Professional Team: Our dedicated professionals ensure flawless execution, from planning and design to on-site management.</li>
                        <li>Attention to Detail: We take care of every detail, allowing you to enjoy a stress-free, seamless event.</li>
                    </ul>
                </div>
                <div className="about-image"/>
            </div>
            <div className="About">
                <div className="about-image"/>
                <div className="About-write-up">
                    <h2>Our Team</h2>
                    <p>The heart of Biz Baz Events is our passionate, detail-oriented team. With a wealth of experience and dedication, our professionals are committed to exceeding your expectations. Their enthusiasm and expertise are what make every event we manage extraordinary.</p>
                </div>
            </div>
            <div className="About">
                <div className="About-write-up">
                    <h2>Our services</h2>
                    <ul>
                        <li>Concept Development: Crafting innovative concepts tailored to the events vision.</li>
                        <li>Artist and Content Curation: Sourcing and coordinating talent and entertainment to enhance any event.</li>
                        <li>Design and Aesthetics: Creating visually stunning environments that align with the theme.</li>
                        <li>Audience Engagement: Ensuring meaningful interaction with attendees.</li>
                        <li>Partnerships and Collaborations: Building strategic alliances to enhance any event’s impact.</li>
                        <li>Logistics and Management: Seamless coordination and execution from start to finish.</li>
                    </ul>
                </div>
                <div className="about-image"/>
            </div>
            
        </div>
    );
};

export default About