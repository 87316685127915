import React from "react";
import './eventpage.css'
import VendorsDec from "./vendors-december";


const Xmaspage = () =>{

    return(
        <>
            <div className="outer-body-event">
                <div className="column1">
                        <img src="/images/banner-24.webp" alt="whatever" />
                </div>
                <div className="column-1">
                    <div className="writeup-body">
                        <h2>Christmas Festival 2024</h2>
                        <h4 style={{textAlign:"left"}}>BIZARRE BAZAAR CHRISTMAS FESTIVAL is back and bigger than ever! 🎉✨<br/> Get ready for a holiday shopping adventure, with unique vendors, unbeatable deals, and endless fun ALL WEEKEND!!!!<br/>📍 Where: @KSTVET, UN Avenue, Gigiri <br/> 📅 When: Dec 13, 14 & 15 
                            <br/>🎪 Step into the season’s ultimate open-air market, packed with handcrafted and other treasures, live entertainment, and festive surprises at every turn! Whether you’re here with friends, family, or flying solo, this is the place to make holiday memories that last.
                        </h4>
                    </div>
                    <div className="writeup-body ticket-section">
                        <h2>TICKETS</h2>
                        <div className="ticketbox">
                            <div className="ticket-card">
                                <h3>Adults</h3>
                                <p>KES 500</p>
                            </div>
                            <div className="ticket-card">
                                <h3>Children</h3>
                                <p>KES 200</p>
                            </div>
                        </div>
                        <div className="ticket-link">
                            <a href="https://kenyabuzz.com/events/event/bizarre-bazaar-christmas-festival" target="_blank" rel="noopener noreferrer" className="buy-tickets-button">
                                Buy Tickets
                            </a>
                        </div>
                    </div>

                    <div className="writeup-body">
                        <img src="/images/april-map-copy.webp" alt="whatever"/>
                    </div> 
                                     
                </div>
                <div className="row2">
                    <h2>Activities</h2>
                    <div className="activities-grid">
                        <div className="grid">
                            <img src="/images/svg-vectors/stall-svgrepo-com.svg" alt="food"/>
                            <li>180+ Vendors </li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/food-dinner-svgrepo-com.svg" alt="food"/>
                            <li>Food & Drink</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/dog-svgrepo-com.svg" alt="dog-friendly"/>
                            <li>Dog Friendly</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/drummer-set-svgrepo-com.svg" alt="food"/>
                            <li>Live Performances<br/>& DJ</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/extreme-sport-acrobats-couple-svgrepo-com (1).svg" alt="food"/>
                            <li>Sarakasi Trust Acrobats</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/playground-svgrepo-com.svg" alt="food"/>
                            <li>Kids Playground</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/baseball-svgrepo-com.svg" alt="food"/>
                            <li>Baseball & Softball Station</li>
                        </div> 
                        <div className="grid">
                            <img src="/images/svg-vectors/baseball-svgrepo-com.svg" alt="food"/>
                            <li>Painting with Onyango</li>
                        </div> 
                        <div className="grid">
                            <img src="/images/svg-vectors/baseball-svgrepo-com.svg" alt="food"/>
                            <li>Paro Pottery</li>
                        </div> 
                        <div className="grid">
                            <img src="/images/svg-vectors/baseball-svgrepo-com.svg" alt="food"/>
                            <li>Biz Baz Kids Club</li>
                        </div>                        

                    </div>
                    
                </div>
                <div className="column1">
                    <VendorsDec/>
                </div>
            </div>
        </>
    )
}

export default Xmaspage