import React, { useState, useEffect } from 'react';
import './slideshow.css';

const Slideshow = ({ images, interval = 8000 }) => { // Ensure interval is 20s
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(timer);
  }, [currentIndex, interval]); // Make sure interval is included in dependencies

  return (
    <div className="hero-section">
      <div className="hero-background" style={{ backgroundImage: `url(${images[currentIndex]})` }}></div>
      <div className="image-house">
        <img src={images[currentIndex]} alt="Slideshow" className="slideshow-image" />
      </div>
    </div>
  );
};

export default Slideshow;

