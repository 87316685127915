import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <>
      {/* Ticker */}
      <div className="ticker-container">
        <div className="ticker-wrapper">
          <div className="ticker">
            <p>Save the Date: Bizarre Bazaar Easter Festival on Sat 5th and Sun 6th April &nbsp; Save the Date: Bizarre Bazaar Easter Festival on Sat 5th and Sun 6th April </p>
            <p>Save the Date: Bizarre Bazaar Easter Festival on Sat 5th and Sun 6th April &nbsp; Save the Date: Bizarre Bazaar Easter Festival on Sat 5th and Sun 6th April</p>
          </div>
        </div>
      </div>

      {/* Navigation Bar */}
      <nav className="navbar">
        <div style={{display:"none"}}> 
          <img src="/cropped-logo.jpg" style={{ height: "60px", border: "solid 1px" }} alt="logo" />
        </div>
        <div className="nav-container">
          <button className="hamburger" onClick={() => setIsOpen(!isOpen)}>
            ☰
          </button>
          <ul className={`nav-items ${isOpen ? "open" : ""}`}>
            <li className="nav-item">
              <Link className="nav-link" to="/home" onClick={() => setIsOpen(false)}>HOME</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about" onClick={() => setIsOpen(false)}>ABOUT</Link>
            </li>
            <li
              className="nav-item dropdown"
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
            >
              <span className="nav-link">EVENTS ▾</span>
              {isDropdownOpen && (
                <ul className="dropdown-menu">
                  <li>
                    <Link className="nav-link" to="/events" onClick={() => setIsOpen(false)}>FESTIVALS</Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/" onClick={() => setIsOpen(false)}>OFFROAD </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={() => setIsOpen(false)}>CONTACT</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/gallery" onClick={() => setIsOpen(false)}>GALLERY</Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
