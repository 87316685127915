import React from "react";
import { Link } from "react-router-dom";
import Countdown from "./countdown";
import './home-about.css'
const Homeabout = () => {
    const targetDate = "April 5, 2025";
    return(
        <div className="spacer layer1">
            <div>
                <h3 style={{color:" #122620;"}}>OUR EVENTS</h3>
            </div>
            <div className="event-cards">
                <div className="festivals">
                    <div>
                        <img src="/images/misc-stills-april-2024/DSC_0104.webp"/>
                        <h3>FESTIVALS</h3>
                        <div>
                            <p>
                            Bizarre Bazaar Festivals showcase African creativity in a festive, family-friendly, open-air setting. Featuring handmade and artisanal goods, these events bring together talented creators, unique vendors, and vibrant performances. Visitors can explore crafts, fashion, and local artistry while enjoying a lively atmosphere celebrating culture, community, and creativity.
                             </p>
                        </div>
                        <div>
                            <Link to="/events">View details</Link>
                        </div>
                    </div>
                </div>
                <div className="offroad">
                    <div className="offroadlost">
                        <img src="/images/offroad-4x4.webp"/>
                        <h3>OFFROAD</h3>
                        <div>
                            <p>
                            Offroad 4x4 Challenges deliver adrenaline-fueled excitement in a rugged, outdoor setting. These family-friendly events showcase skilled drivers conquering tough terrains, from rocky trails to deep mud. Spectators enjoy thrilling races, technical obstacles, and roaring engines while celebrating adventure, teamwork, and the off-road community’s passion for exploration and endurance.                            
                            </p>
                            
                        </div>
                        <div>
                            <Link to="/">View details</Link>
                        </div>
                    </div>
                </div>
            </div>
                
            
        </div>
    )
}
export default Homeabout