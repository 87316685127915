import React, { useState } from "react";
import brandsData from "../json-files/vendors-apr-2025.json";
import VendorModal from "./vendor-modal";
import "./vendor-april.css";

const VendorCard = ({ searchQuery = "" }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const query = typeof searchQuery === "string" ? searchQuery.toLowerCase() : "";
    const brands = brandsData.brands
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .filter((brand) => brand.name.toLowerCase().includes(query));

    return (
        <>
            <div className="vendor-grid">
                {brands.map((brand, index) => {
                    const logourl = brand.logo ? `/imgs/logos/${brand.logo}` : `/imgs/logos/user.svg`;
                    const hasDiscount = brand.discount && brand.discount.trim() !== "";

                    return (
                        <div
                            className={`vendor-square ${hasDiscount ? "highlight-green" : ""}`}
                            key={index}
                            onClick={() => {
                                setModalData({ ...brand, logourl });
                                setIsModalOpen(true);
                            }}
                        >
                            {hasDiscount && (
                                <div className="discount-badge">
                                    {brand.discount}
                                </div>
                            )}
                            <div className="vendor-inner-square">
                                <img src={logourl} alt={`${brand.name} logo`} />
                                <h3>{brand.name}</h3>
                            </div>
                        </div>
                    );
                })}
            </div>

            {/* Modal Component (renders only when modal is open) */}
            {isModalOpen && modalData && (
                <VendorModal data={modalData} closeModal={() => setIsModalOpen(false)} />
            )}
        </>
    );
};

export default VendorCard;

