import React from "react";
import './footer.css'


const GlobalFooter = () =>{
    return(
        <>
            <div className="footer">
                <div className="socials">
                    <h2>Socials:</h2>
                    <a
                    class='socials'
                    href="https://instagram.com/bizbazevents/"
                    target='_blank'
                    rel="noopener"
                    aria-label='Github'
                    >
                        <img src="/images/instagram.svg" style={{width:"40px"}}/>
                    </a>
                    <a 
                    class='socials'
                    href="https://facebook.com/@bizbazevents/"
                    target='_blank'
                    rel="noopener"
                    aria-label='Github'
                    ><img src="/images/facebook.svg" style={{width:"40px"}}/>
                    </a>
                </div>
                <div className="reach">
                    <h2>Reach us:</h2>
                    <p>
                        Call - 0701 395 902
                    </p>
                    <p>
                        Email - info@bizbazevents.com
                    </p>
                </div>
                <div className="Location">
                    <h2>Office</h2>
                    <p>Nairobi Garage,<br/> Karen</p>
                </div>
            </div>
        </>
    )
}

export default GlobalFooter