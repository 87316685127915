import React from "react";
import './eventpage.css'
import VendorCard from "../components/cards-april2024";


const Eventpage = () =>{

    return(
        <>
            <div className="outer-body-event">
                <div className="column1" style={{display:"none"}}>
                        <img src="/images/banner-24.webp" alt="whatever" />
                </div>
                <h2 style={{fontWeight:'900', fontSize:"1.5rem",color: '#ff6f61', textAlign:'center'}}>Overview</h2>
                <div className="column-1">
                    
                    <div className="writeup-body">
                        <h2 style={{fontWeight:'900', fontSize:"1.5rem"}}>Easter Festival 2025</h2>
                        <p style={{textAlign:"center"}}>Get ready for Nairobi’s largest and most diverse, open-air art, craft, and artisanal market—a vibrant celebration of creativity, culture, and community! This Easter edition is packed with top-notch entertainment, live action, and a diverse selection of proudly African and global products, all with exclusive seasonal discounts! 🛍✨</p>
                        <br/>
                        <p style={{textAlign:"left"}}>📅 Dates: Saturday 5th & Sunday 6th April<br></br>
                        📍 Location: @KSTVET, off UN Avenue, Gigiri<br></br>
                        🕘 Hours: 9 AM – 7 PM daily</p>
                        <br></br>
                        <p style={{textAlign:"left"}}>
                        📲 Stay Connected!<br/> Follow us on social media or contact us for more details:
                        📧 info@bizbazevents.com | 📞 0701 395 902 <br/>
                        Join us for a weekend of shopping, entertainment, and fun for everyone! 🎉✨

                        </p>
                    </div>
                    <div className="writeup-body">
                        <img src="/images/april-map-copy.webp" alt="whatever"/>
                    </div>
                    <div className="writeup-body ticket-section">
                        <h2 style={{fontWeight:'900', fontSize:"1.5rem"}}>Tickets</h2>
                        <div className="ticketbox">
                            <div className="ticket-card">
                                <h3>Adults</h3>
                                <p>KES 500</p>
                            </div>
                            <div className="ticket-card">
                                <h3>Children</h3>
                                <p>KES 200</p>
                            </div>
                        </div>
                        <div className="ticket-link">
                            <a href="https://kenyabuzz.com/events/event/bizarre-bazaar-easter-festival-2025" target="_blank" rel="noopener noreferrer" className="buy-tickets-button">
                                Buy Tickets
                            </a>
                        </div>
                    </div>

                   
                                     
                </div>
                <div className="row2">
                    <h2 style={{fontWeight:'900', fontSize:"1.5rem",}}>Activities</h2>
                    <div className="activities-grid">
                        <div className="grid">
                            <img src="/images/svg-vectors/stall-svgrepo-com.svg" alt="food"/>
                            <li>100+ Vendors </li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/food-dinner-svgrepo-com.svg" alt="food"/>
                            <li>Food & Drink</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/dogs.svg" alt="dog-friendly"/>
                            <li>Dog Friendly</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/drummer-set-svgrepo-com.svg" alt="food"/>
                            <li>Live Entertainment<br/>& DJs</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/easter-egg.svg" alt="food"/>
                            <li>Easter Egg Hunt</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/playground-svgrepo-com.svg" alt="food"/>
                            <li>Kids Playground</li>
                        </div>
                        <div className="grid">
                            <img src="/images/svg-vectors/raffle.svg" alt="food"/>
                            <li>Raffle</li>
                        </div> 
                        <div className="grid">
                            <img src="/images/svg-vectors/paint-brush-2-svgrepo-com (4).svg" alt="food"/>
                            <li>Painting with Onyango</li>
                        </div> 
                        <div className="grid">
                            <img src="/images/svg-vectors/vase-svgrepo-com.svg" alt="food"/>
                            <li>Paro Pottery</li>
                        </div> 
                        <div className="grid">
                            <img src="/images/svg-vectors/badpiggies-hd-svgrepo-com.svg" alt="food"/>
                            <li>Biz Baz Kids Club</li>
                        </div>                        

                    </div>
                    
                </div>
                <div style={{padding:"20px"}}>
                    <VendorCard/>
                </div>
            </div>
        </>
    )
}

export default Eventpage
