import React from 'react';
import Slideshow from '../components/slideshow';
import Homeabout from '../components/home-about';
import Offroadhome from '../components/offroad-home';
import './home.css'
import Collabo from '../components/collabs';

const images = [
    '/slideshow/Biz_Baz_Easter_2025_Edition_Nomad_Magazine_advert[1].jpg',
    '/slideshow/slide2.jpg',
    '/slideshow/slide3.jpg',
    '/slideshow/slide4.jpg',
    '/slideshow/slide5.jpg'
]

const Homepage = () =>{
    return(
        <>
            <div className='Hero'>
                <Slideshow images={images} interval={5000} /> 
            </div>
            <Homeabout/>
        </>
    );
};

export default Homepage