import React, { useState } from "react";
import "./vendor-april.css"; // Ensure styles are defined

const VendorModal = ({ data, closeModal }) => {
    const [activeTab, setActiveTab] = useState("overview");

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <button className="close-btn" onClick={closeModal}>✖</button>

                {/* Business Header (Logo + Name) */}
                <div className="vendor-header">
                    <img src={data.logourl} alt={data.name} className="vendor-logo" />
                    <div>
                        <h2>{data.name}</h2>
                        <p className="vendor-category">{data.category || "Business"}</p>
                    </div>
                </div>

                {/* Tab Navigation */}
                <div className="tab-navigation">
                    <button 
                        className={activeTab === "overview" ? "active" : ""}
                        onClick={() => setActiveTab("overview")}
                    >
                        Overview
                    </button>
                    <button 
                        className={activeTab === "photos" ? "active" : ""}
                        onClick={() => setActiveTab("photos")}
                    >
                        Photos
                    </button>
                    <button 
                        className={activeTab === "Discount" ? "active" : ""}
                        onClick={() => setActiveTab("Discount")}
                    >
                        Discount
                    </button>
                    <button 
                        className={activeTab === "contact" ? "active" : ""}
                        onClick={() => setActiveTab("contact")}
                    >
                        Contact
                    </button>
                </div>

                {/* Tab Content */}
                <div className="tab-content">
                    {activeTab === "overview" && (
                        <div className="overview-section">
                            <h3>About {data.name}</h3>
                            <p>{data.description || "No description available."}</p>
                            <hr />
                        </div>
                    )}
                    {activeTab === "photos" && (
                        <div className="photos-section">
                            <h3>Photos</h3>
                            <div className="photo-grid">
                                {data.photos && data.photos.length > 0 ? (
                                    data.photos.map((photo, i) => (
                                        <img key={i} src={photo} alt={`Vendor photo ${i}`} className="photo-item" />
                                    ))
                                ) : (
                                    <p>No photos available.</p>
                                )}
                            </div>
                        </div>
                    )}
                    {activeTab === "Discount" && (
                        <div className="reviews-section">
                            <h3>Discount info</h3>
                            <p style={{color:"green"}}>{data.discount_info || "This stall doesnt have any offers currently."}</p>
                        </div>
                    )}
                    {activeTab === "contact" && (
                        <div className="contact-section">
                            <h3>Contact</h3>
                            <p><strong>Phone:</strong> {data.phone || "N/A"}</p>
                            <p><strong>Email:</strong> {data.email || "N/A"}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VendorModal;
