import React from "react";
import brandsData from '../json-files/vendors.json';
import './vendors.css';

const Vendorcard = ({ searchQuery = "", openModal, setModalData }) => {
    const query = typeof searchQuery === "string" ? searchQuery.toLowerCase() : "";
    const brands = brandsData.brands
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .filter((brand) => brand.name.toLowerCase().includes(query));

    return (
        <>
            {brands.map((brand, index) => {
                const logourl = brand.logo ? `/imgs/logos/${brand.logo}` : `/imgs/logos/user.svg`;
                return (
                    <div
                        className="vendor-square"
                        key={index}
                        onClick={() => {
                            setModalData({
                                ...brand,
                                logourl // Pass logourl correctly
                            });
                            openModal();
                        }}
                    >
                        <div className="vendor-inner-square">
                            <img src={logourl} alt={`${brand.name} logo`} />
                            <h3>{brand.name}</h3>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default Vendorcard;
