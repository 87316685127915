import React from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import './poster.css'

const Posters = ({imagepath,headertext, venue, date,button, vendors, vendorslink}) => {
    return(
        <div className="poster-box">
            <div className="poster-inner">
                <img src={imagepath} alt="poster" className="poster-image" />
            </div>
            <div className="poster-body">
                <h2>{headertext}</h2>
                <div className="middletext">
                    <h4>VENUE: </h4><p>{venue}</p>
                    <h4>DATE: </h4><p>{date}</p>
                    <h4>VENDORS: </h4><p>{vendors}</p>
                    <Link to = {button} ><button> View Details</button></Link>
                </div>
            </div>
        </div>
    )
}
Posters.propTypes = {
    imagepath: PropTypes.string.isRequired,
    headertext: PropTypes.string.isRequired,
    venue: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    vendors:PropTypes.string.isRequired,
    vendorslink:PropTypes.string.isRequired
}
export default Posters