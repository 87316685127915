import React from "react";
import VendorcardDec from "../components/vendor-card-2024-dec";
import './vendor.css'
import SearchBar from "../components/searchbar";
import { useState } from "react";

const VendorsDec =()=>{
    const [searchQuery, setSearchQuery] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div>
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <diV className='vendors-container'>
                <VendorcardDec
                    searchQuery={searchQuery}
                    openModal={openModal}
                    setModalData={setModalData}
                />
            </diV>
            
            {/* Modal */}
            {isModalOpen && modalData && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        
                        <button className="close-button" onClick={closeModal}>X</button>
                        <img src={`/imgs/logos/${modalData.logo}`} style={{width:"200px"}}></img>
                        <h2 style={{textAlign:"left"}}>{modalData.name}</h2>
                        <p style={{textAlign:"justify", wordSpacing:"-2px"}}>{modalData.description}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VendorsDec